.full-page {
	display: flex;
}

.navbar {
	padding-left: 5%;
	padding-top: 5%;
	padding-bottom: 5%;
	padding-right: 0;
}
.nav-item {
	position: relative;
	/* border-radius: 25px 0px 0px 25px; */
}
.css-191imvk::before {
	content: "";
	position: absolute;

	background-color: transparent;
	bottom: -50px;
	height: 50px;
	right: 0;
	width: 25px;
	border-top-right-radius: 25px;
	box-shadow: 0 -25px 0 0 #ffff;
}

.css-gr69ss::before:hover {
	content: "";
	position: absolute;

	background-color: transparent;
	bottom: -50px;
	height: 50px;
	right: 0;
	width: 25px;
	border-top-right-radius: 20px;
	box-shadow: 0 -20px 0 0 #ffff;
}
