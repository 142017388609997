.CircularProgressbar-text{
  padding-right: 100px !important;
  margin-top: 150px !important;
 
}
.circular{
  justify-content: center !important;
  align-items: center !important;
  display: flex !important;
  height: 100px !important;
  width: 100px !important;
  align-content: center !important;
  text-align: center !important;

}

.CircularProgressbar {
  justify-content: center !important;
  align-items: center !important;
  display: flex !important;
  height: 100px !important;
  width: 100px !important;
  align-content: center !important;
  text-align: center !important;

}
.css-1od6a13{
  justify-content: center !important;
  align-items: center !important;
  display: flex !important;
  height: 100px !important;
  width: 100px !important;
  align-content: center !important;
  text-align: center !important;

}