li{
  color: var(--color-primer);
  font-weight: var(--font-weight-500);
  padding: 1px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  display: inline;
  justify-content: center;
  height: auto;
  cursor: pointer;;
  border: 1px;
}
ul{
  list-style:none;
  display: flex;
}
.next,.previous{
  justify-content: center;
  display: flex;
  align-items: center;
  text-align: center;
  align-content: center;
}
.next{
  margin-left: auto;
}
.page-item{
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
}
.page-item-active{
  justify-content: center;
  display: flex;
  align-items: center;
  text-align: center;
  align-content: center;
  border-radius: 5px;
  background-color: var(--color-secondary);
  color: var(--color-on-primary);
}
p{
  color: var(--color-primer);
  font-weight: var(--font-weight-500);
  padding: 1px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  justify-content: center;
  display: flex;
  align-items: center;
  text-align: center;
  align-content: center;
  margin-right: 10px;
  display: inline;
  justify-content: center;
  height: auto;
  border: 1px;
}

